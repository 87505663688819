import { Api } from "./api";

export default {
  getProductByMultipleIds: (payload) =>
    Api.post(`/v2/products/multiple-id`, {
      ids: payload.ids,
    }),
  getByCategoryId: (payload) =>
    Api.get(`/v2/products/category/${payload.category_id}`, {
      params: {
        limit: payload.limit,
        page: payload.page,
        condition: payload.condition,
        min_price: payload.min_price,
        max_price: payload.max_price,
        sort: payload.sort,
        services: payload.services,
        location: payload.location,
        premium: payload.premium,
      },
    }),
  updateViewed: (payload) =>
    Api.post(
      `/v1/product-views/${payload.id}`,
      {},
      {
        headers: {
          token: payload.token,
        },
      }
    ),
  getViewed: (payload) => Api.get(`/v1/product-views/${payload.id}`),
  getSold: (payload) => Api.get(`/v1/product-solds/${payload.id}`),
  getBySearchName: (payload) =>
    Api.post(
      `/v2/products/search`,
      {
        search: payload.search,
        condition: payload.condition,
        min_price: payload.min_price,
        max_price: payload.max_price,
        store_id: payload.store_id,
        sort: payload.sort,
        services: payload.services,
        location: payload.location,
        premium: payload.premium,
      },
      {
        params: {
          limit: payload.limit,
          page: payload.page,
        },
      }
    ),
  getProductById: (payload) => Api.get(`/v1/products/${payload.id}`),
  getProductByStoreId: (payload) =>
    Api.get(`/v2/products/store/${payload.store_id}`, {
      params: {
        limit: payload.limit,
        page: payload.page,
        condition: payload.condition,
        min_price: payload.min_price,
        max_price: payload.max_price,
        sort: payload.sort,
        showcase_id: payload.showcase_id,
      },
    }),
  getProductSubdomain: (payload) =>
    Api.get(
      `/v2/products/${payload.subdomain_shop}/${payload.subdomain_product}`
    ),
  getDiscussionByProductId: (payload) =>
    Api.get(`/v1/discussions/product/${payload.product_id}`, {
      params: {
        page: payload.page,
        limit: payload.limit,
      },
    }),
  getReviewByProductId: (payload) =>
    Api.get(`/v1/points/product/${payload.product_id}`, {
      params: {
        page: payload.page,
        limit: payload.limit,
      },
    }),
  getCourierByStoreId: (payload) => Api.get(`/v1/services/${payload.store_id}`),
  getDiscussionById: (payload) =>
    Api.get(`/v1/discussions/one/${payload.discussion_id}`),
  createDiscussion: (payload) =>
    Api.post(`/v1/discussions`, payload.data, {
      headers: { token: payload.token },
    }),
  createComment: (payload) =>
    Api.post(`/v1/comments`, payload.data, {
      headers: { token: payload.token },
    }),
  getOtherProducts: (payload) =>
    Api.get(`/v2/products/store/${payload.store_id}`, {
      params: {
        limit: payload.limit,
        page: payload.page,
      },
    }),
};
