import axios from "axios";
import config from "../config";
export const apiUrl =
  process.env.NODE_ENV === "development"
    ? config.development.API_BASE_URL
    : config.production.API_BASE_URL;
export const apiPlasgosExpress =
  process.env.NODE_ENV === "development"
    ? config.development.API_EXPEDITION
    : config.production.API_EXPEDITION;
export const hostWeb =
  process.env.NODE_ENV === "development"
    ? config.development.BASE_URL
    : config.production.BASE_URL;
export const hostMobile =
  process.env.NODE_ENV === "development"
    ? config.development.BASE_URL_MOBILE
    : config.production.BASE_URL_MOBILE;
export const sellerWeb =
  process.env.NODE_ENV === "development"
    ? config.development.SELLER_BASE_URL
    : config.production.SELLER_BASE_URL;
export const wcastWeb =
  process.env.NODE_ENV === "development"
    ? config.development.WCAST_BASE_URL
    : config.production.WCAST_BASE_URL;
export const clientIdGoogle =
  process.env.NODE_ENV === "development"
    ? config.development.CLIENT_ID_GOOGLE
    : config.production.CLIENT_ID_GOOGLE;
export const apiGoogleMap =
  process.env.NODE_ENV === "development"
    ? config.development.API_KEY_GOOGLE_MAP
    : config.production.API_KEY_GOOGLE_MAP;
export const Api = axios.create({ baseURL: apiUrl });
export const ApiExpress = axios.create({ baseURL: apiPlasgosExpress });
